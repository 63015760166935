import Model from "../Model";

class AreaAmounts extends Model {
    buildUrl ({ param }) {
        return ['area_amounts', param];
    }

    async byId(form) {
        return await this.request({
            method: 'POST',
            url: 'area_amounts',
            data: form
        });
    }

    async store(form) {
        return await this.request({
            method: 'POST',
            url: 'area_amounts/store',
            data: form
        });
    }

    async update(form) {
        return await this.request({
            method: 'POST',
            url: 'area_amounts/update',
            data: form
        });
    }

    async destroy(form) {
        return await this.request({
            method: 'POST',
            url: 'area_amounts/destroy',
            data: form
        });
    }
}

export default new AreaAmounts;